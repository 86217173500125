import urlcat from 'urlcat';
import { fetchWrapped } from './';
import { searchApiV3 } from './endpoints';

const getProductItem = async (productId, searchApiParams, options) =>
  await fetchWrapped(urlcat(searchApiV3, `/product/${productId}${searchApiParams}`), options);
const getProductOffers = async (options) => await fetchWrapped(urlcat(searchApiV3, '/product-offers'), options);
const getFacets = async (options) => await fetchWrapped(urlcat(searchApiV3, '/facets'), options);
const getFullFacets = async (options) => await fetchWrapped(urlcat(searchApiV3, '/facet-full'), options);
const getSearchData = async (options) => await fetchWrapped(urlcat(searchApiV3, '/search'), options);
const getSuggestions = async (options) => await fetchWrapped(urlcat(searchApiV3, '/suggestions'), options);
const getProductProfile = async (options) => await fetchWrapped(urlcat(searchApiV3, '/products'), options);
const getWishlistProfile = async (options) => await fetchWrapped(urlcat(searchApiV3, '/products'), options);

export default {
  getProductItem,
  getProductOffers,
  getSuggestions,
  getSearchData,
  getFacets,
  getFullFacets,
  getProductProfile,
  getWishlistProfile,
};
