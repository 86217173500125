import contentApi from './contentApi';
import searchApi from './searchApi';
import userApi from './userApi';
import taxiApi from './taxiApi';
import staticApi from './staticApi';
import reviewsApi from './reviewsApi';
import cartApi from './cartApi';
import stripeApi from './stripeApi';
import shopApi from './shopApi';
import productApi from './productApi';
import discountApi from './discountApi';
import pocApi from './pocApi';
import fetch from 'node-fetch';
import imageApi from './imageApi';
import { isProduction } from '../utils/isProduction';
import { parseValidJSON } from '../utils/parseValidJSON';

const fetchLogging = !isProduction;

const validateFetchOptions = (url, options) => {
  if (options?.body && typeof options.body !== 'string')
    throw new Error(`Please provide options.body as a string. Attempt to request: ${url}`);
};

const createFrontloadReponse = async (res, getJSON) => ({
  status: res.status,
  ok: res.ok,
  body: getJSON ? await res.json() : await res.text(),
  url: res.url,
  isJSON: getJSON,
});

let failFetchWithException = false,
  failFetchWith500 = false;

if (typeof window !== 'undefined') {
  window.toggleFetchWithException = () => {
    localStorage.setItem('failFetchWithException', !failFetchWithException);
  };
  window.toggleFetchWith500 = () => {
    localStorage.setItem('failFetchWith500', !failFetchWith500);
  };

  const failFetchWithExceptionLS = parseValidJSON(localStorage.getItem('failFetchWithException'));
  const failFetchWith500LS = parseValidJSON(localStorage.getItem('failFetchWith500'));
  if (failFetchWithExceptionLS) failFetchWithException = JSON.parse(failFetchWithExceptionLS);
  if (failFetchWith500LS) failFetchWith500 = JSON.parse(failFetchWith500LS);
}

const fetchWrapped = async (url, options) => {
  const requestStartTime = new Date().getTime();

  return fetch(url, options).then(async (res) => {
    const isJSONResponse = res.headers?.get('Content-Type')?.includes('application/json');
    const flResponse = await createFrontloadReponse(res, isJSONResponse);
    const requestEndTime = new Date().getTime();
    validateFetchOptions(url, options);
    if (fetchLogging)
      console.log({
        env: process.env.NODE_ENV,
        url,
        options,
        status: flResponse.status,
        duration: requestEndTime - requestStartTime,
      });

    if (failFetchWithException) throw new Error('Fetch failed by request. ' + url);
    if (failFetchWith500) {
      console.error(
        `Request failed manually with status 500 .\nURL: ${url}\nFetch options object: \n${JSON.stringify(options)}`
      );
      return {
        ok: false,
        status: 500,
        body: '<ResponseError: {"ok":false,"message":"The requested resource is currently unavailable."}',
      };
    }
    if (!flResponse.ok) {
      console.error(
        `Request failed with status ${flResponse.status}.\nURL: ${url}\nFetch options object: \n${JSON.stringify(
          options
        )}\nResponse message: \n'${flResponse.body}'`
      );
    }

    return flResponse;
  });
};
//Don't catch errors here bacause frontload won't set frontload.error to true
/**
 * Use getValidResponseBody to get a response body from not failed request
 *
 * @param {*} flItem - fetchWrapped function response
 * @returns body of response object if flItem?.ok is true, otherwise it returns null
 */
const getValidResponseBody = (flItem) => (flItem?.ok ? flItem?.body : null);

function PostOptions(args) {
  const { headers, bodyObject } = args;
  const headersParam = headers ? { headers } : {};
  return {
    ...headersParam,
    method: 'POST',
    body: JSON.stringify(bodyObject),
  };
}

const applyModifiers = (initialValue, fnArray) => fnArray.reduce((curValue, fn) => fn(curValue), initialValue);

const addUserJWT = (userJWT) => (headers) => (userJWT ? { ...headers, Authorization: `Bearer ${userJWT}` } : headers);

const addXCartSession = (cartSession) => (headers) =>
  cartSession ? { ...headers, 'X-Cart-Session': cartSession } : headers;

const addContentTypeJson = (headers) => ({ ...headers, 'Content-Type': 'application/json' });

export {
  fetchWrapped,
  contentApi,
  searchApi,
  userApi,
  taxiApi,
  staticApi,
  stripeApi,
  reviewsApi,
  cartApi,
  shopApi,
  productApi,
  discountApi,
  getValidResponseBody,
  PostOptions,
  applyModifiers,
  addUserJWT,
  addContentTypeJson,
  addXCartSession,
  pocApi,
  imageApi,
};
