import urlcat from 'urlcat';
import { fetchWrapped } from '.';

import { reviewsApi } from './endpoints';

const getProductReviews = async (productId, ean) =>
  await fetchWrapped(urlcat(reviewsApi, '/product', { offer: productId, ean }));

export default {
  getProductReviews,
};
