import urlcat from 'urlcat';
import { PostOptions, fetchWrapped } from './';

import { discountApi } from './endpoints';

const getHomePageDiscounts = async () => await fetchWrapped(urlcat(discountApi, '/v1/homepage/'), new PostOptions({}));

export default {
  getHomePageDiscounts,
};
