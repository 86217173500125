import { isK8S } from '../utils/isServer';

const apiV3Origin = isK8S ? process.env.REACT_APP_API_K8S_ORIGIN : process.env.REACT_APP_API_V3_ORIGIN;

export const contentApiV3 = apiV3Origin + process.env.REACT_APP_CONTENT_API_PATH;
export const contentV3ApiV3 = apiV3Origin + process.env.REACT_APP_CONTENT_API_PATH.replace('/v1', '/v3');
export const searchApiV3 = apiV3Origin + process.env.REACT_APP_SEARCH_API_PATH;
export const userApiV3 = apiV3Origin + process.env.REACT_APP_USER_API_V3_PATH;
export const taxiApiV3 = apiV3Origin + process.env.REACT_APP_TAXI_API_PATH;
export const staticApi = process.env.REACT_APP_STATIC_DOMAIN;
export const reviewsApi = apiV3Origin + process.env.REACT_APP_REVIEWS_API_PATH;
export const cartApi = process.env.REACT_APP_CART_API_ORIGIN + process.env.REACT_APP_CART_API_PATH;
export const cartApiV1 =
  process.env.REACT_APP_CART_API_ORIGIN + process.env.REACT_APP_CART_API_PATH.replace('/v0', '/v1');
export const stripeApi = process.env.REACT_APP_CART_API_ORIGIN + process.env.REACT_APP_STRIPE_API_PATH;
export const cartGraphQLApi = process.env.REACT_APP_CART_API_ORIGIN + process.env.REACT_APP_CART_GRAPHQL_API_PATH;
export const shopApiV2 = apiV3Origin + process.env.REACT_APP_SHOP_API_PATH;
export const productApi = apiV3Origin + process.env.REACT_APP_PRODUCT_API_PATH;
export const productV3Api = apiV3Origin + process.env.REACT_APP_PRODUCT_API_PATH.replace('/v2', '/v3');
export const discountApi = apiV3Origin + process.env.REACT_APP_DISCOUNT_API_PATH;
export const recommendationApi = apiV3Origin + '/api/recommendations/v1';
export const puggyApi = process.env.REACT_APP_PUGGY_WEB_SHOP;
export const userPreferencesApi = apiV3Origin + '/api/userPreferences/v1';
