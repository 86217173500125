import urlcat from 'urlcat';
import { fetchWrapped } from './';
import { puggyApi } from './endpoints';

const getImageSearchResults = async (options) => await fetchWrapped(urlcat(puggyApi, '/api/img2'), options);

const uploadImageFile = async (options) => await fetch(urlcat(puggyApi, '/api/file'), options);

const uploadImageLink = async (options) => await fetchWrapped(urlcat(puggyApi, '/api/link'), options);

export default {
  getImageSearchResults,
  uploadImageFile,
  uploadImageLink,
};
