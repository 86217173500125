import urlcat from 'urlcat';
import { fetchWrapped } from './';

import { shopApiV2 } from './endpoints';

const addReview = async (options) => await fetchWrapped(urlcat(shopApiV2, '/addReview'), options);

export default {
  addReview,
};
