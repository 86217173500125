import urlcat from 'urlcat';
import { PostOptions, fetchWrapped } from './';

import { productApi, productV3Api } from './endpoints';

const addReview = async (options) => await fetchWrapped(urlcat(productApi, '/addReview'), options);
const getMoreLikeThis = async (productIds) =>
  await fetchWrapped(
    urlcat(productV3Api, '/moreLikeThis'),
    new PostOptions({ bodyObject: { productIds: productIds?.slice(0, 30) } })
  );
const subscribeBackInStock = async (options) =>
  await fetchWrapped(urlcat(productV3Api, '/subscribeBackInStock'), { ...options, method: 'POST' });

const unsubscribeProduct = async (options) =>
  await fetchWrapped(urlcat(productV3Api, '/removeBackInStockSubscription'), { ...options, method: 'DELETE' });

export default {
  addReview,
  getMoreLikeThis,
  subscribeBackInStock,
  unsubscribeProduct,
};
